import React from 'react';
import {chunk, last} from 'lodash';
import * as PropTypes from 'prop-types';
import ArticleCard from '../../components/ArticleCard';
import * as styles from './articleSection.module.scss';

const ArticleSection = ({
  articles,
  sectionTitle,
  largeArticle,
  press,
  linkText,
  linkUrl,
  theme,
}) => {

  createLinkCard(articles, linkUrl, linkText);

  const allArticlesData = articles && articles.length > 0 ? articles.map((article) => {
    return article.node ? article.node : article;
  }) : null;

  const largeArticleData = largeArticle && allArticlesData ? allArticlesData[0] : null;
  const articlesData = allArticlesData ? chunk(largeArticleData ? allArticlesData.slice(1): allArticlesData, 2) : null;

  return (
    <div className={[styles.articleSection, styles[theme]].join(' ')}>

      <header className={styles.header}>
        {sectionTitle && (
          <div className={styles.title}>
            <h6>{sectionTitle}</h6>
          </div>
        )}
      </header>

      <div className={styles.inner}>

        {largeArticleData && (
          <div id='article-section-large-article' className={[styles.row, styles.oneChild].join(' ')}>
            <div className={[styles.card, styles.onlyChild].join(' ')}>
                <ArticleCard
                  article={largeArticleData}
                  press={press}
                  onlyChild={true}
                />
            </div>
          </div>
        )}

        {articlesData &&
          articlesData.map((row, i) => {
            return (
                <div key={i} className={styles.row}>
                  {row.map((article, i) => {
                    return (
                        <div key={i} className={styles.card}>
                          {article && <ArticleCard article={article} press={press} />}
                        </div>
                    );
                  })}
                </div>
            );
        })}

      </div>

    </div>
  );
};

function createLinkCard(articles, linkUrl, linkText) {
  const lastArticle = last(articles);

  if (lastArticle && !lastArticle.articleLinkCard) {
    return (
      linkText && linkUrl && articles.push({ title: linkText, url: linkUrl, articleLinkCard: true })
    );
  }
}

export default ArticleSection;

ArticleSection.propTypes = {
  articles: PropTypes.array,
  sectionTitle: PropTypes.string,
  largeArticle: PropTypes.bool,
  press: PropTypes.bool,
  link: PropTypes.string,
  theme: PropTypes.string,
};
