import React from 'react';
import * as PropTypes from 'prop-types';

import Seo from '../components/SEO';
import Theme from '../../src/components/Theme';
import Introduction from '../../src/components/AboutPage/Introduction';
import PeopleList from '../../src/components/AboutPage/PeopleList';
import Values from '../../src/components/AboutPage/Values';
import ArticleSection from '../components/ArticleSection';
import Newsletter from '../components/Newsletter';
import Timeline from '../components/AboutPage/Timeline';
import GeneralLayout from '../layouts/index';
import { graphql } from 'gatsby';

const AboutPageTemplate = ({ transition, data }) => {
  const { contentfulAboutPage, contentfulNewsletter, site } = data;
  const { valueList, people, recentPress } = contentfulAboutPage;

  return (
    <GeneralLayout>
      <Theme theme="blue" transition={transition}>
        <Seo
          siteData={site}
          seoData={contentfulAboutPage.seo}
          defaultTitle="Emergence - About"
          pathName="/about"
        />
        <Introduction aboutPage={contentfulAboutPage} />
        {valueList && <Values values={valueList} />}
        <Timeline aboutPage={contentfulAboutPage} />
        {people && <PeopleList people={people} />}
        {recentPress && (
          <ArticleSection
            articles={recentPress}
            sectionTitle="Recent Press"
            largeArticle={false}
            press={true}
            linkText="More Press"
            linkUrl="/press"
            theme="white"
          />
        )}
        <Newsletter content={contentfulNewsletter} />
      </Theme>
    </GeneralLayout>
  );
};

AboutPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPageTemplate;

export const pageQuery = graphql`
  query aboutPageQuery {
    contentfulAboutPage {
      ...IntroductionQuery
      ...TimelineQuery
      ...PeopleQuery
      ...ValuesQuery
      recentPress {
        title
        url
        publication
        publishedDate(formatString: "MM.DD.YYYY")
      }
      seo {
        customTitle
        metaDescription
        shareImage {
          file {
            url
          }
        }
      }
    }
    ...NewsletterQuery
    ...SiteQuery
  }
`;
