import React from 'react';
import { Link, graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import Image from '../../Image';
import OutlineHeading from '../../OutlineHeading';
import PersonInfo from '../PersonInfo';

import * as styles from './peopleList.module.scss';


const PeopleList = ({ people }) => (
  <section id="peopleList" className={styles.section}>
    <OutlineHeading title="Our Team" />
    <ul className={styles.people}>
      {people.map((p, i) => {
        return (
          <li key={i} className={styles.person}>
            <Link to={`/people/${p.slug}`}>
              {p.portrait && (
                <Image
                  className={styles.headshot}
                  fluid={p.portrait.fluid}
                  alt={`Portrait of ${p.name}`}
                />
              )}
              <PersonInfo name={p.name} title={p.title} />
            </Link>
          </li>
        );
      })}
    </ul>
  </section>
);

export default PeopleList;

PeopleList.propTypes = {
  people: PropTypes.array.isRequired,
  isPartners: PropTypes.bool,
};

export const query = graphql`
  fragment PeopleQuery on ContentfulAboutPage {
    people {
      name
      title
      slug
      portrait {
        fluid {
          src
          srcSet
        }
      }
    }
  }
`;
