import React from 'react';
import * as PropTypes from 'prop-types';
import '../../styles/experimental/illustration-text-stroke.scss';
import * as styles from './outlineHeading.module.scss';

const OutlineHeading = ({ title }) => {

  return (
    <h2 className={[styles.outlineHeading, 'text-stroke-outline-heading'].join(' ')}>
      {title}
    </h2>
  );
};

OutlineHeading.propTypes = {
  title: PropTypes.string.isRequired,
};

export default OutlineHeading;
