import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Image from '../../Image';
import TextUnderline from '../../TextUnderline';
import * as styles from './values.module.scss';

const Values = ({ values }) => (
  <div className='section'>
    <ul className={styles.values}>
      {values.map((v, i) => {
        return (
          <li className={styles.value} key={i}>
            <div className={styles.content}>
              <h2 className={styles.title}>
                {v.headline && <span dangerouslySetInnerHTML={{ __html: v.headline }} />}
                {v.boldHeadline && (
                  <strong>
                    <TextUnderline
                      headline={v.boldHeadline}
                      headlineUnderline={v.headlineUnderline}
                    />
                  </strong>
                )}
              </h2>
              {v.content && <p className={styles.description}>{v.content.content}</p>}
            </div>
            {v.image && (
              <figure className={styles.imageWrapper}>
                <Image className={styles.image} fluid={v.image.fluid} alt={v.image.title} />
              </figure>
            )}
          </li>
        );
      })}
    </ul>
  </div>
);

export default Values;

Values.propTypes = {
  values: PropTypes.array.isRequired,
};

export const query = graphql`
  fragment ValuesQuery on ContentfulAboutPage {
    valueList {
      headline
      boldHeadline
      headlineUnderline
      content {
        content
      }
      image {
        title
        description
        fluid {
          src
          srcSet
        }
      }
    }
  }
`;
