import React from 'react';
import helpers from '../../../../lib/helpers';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Button from '../../Button';
import TextUnderline from '../../TextUnderline';
import ContentfulText from '../../ContentfulText';
import * as styles from './introduction.module.scss';

const Introduction = ({ aboutPage }) => (
  <section className={styles.introduction}>
    <div className={styles.inner}>
      <h1 className={styles.title}>
        <TextUnderline
          headline={aboutPage.headline}
          headlineUnderline={aboutPage.headlineUnderline}
          underlineTheme="canvasMint"
        />
      </h1>
      <div className={styles.summary}>
        <ContentfulText textObj={aboutPage.summary} />
      </div>
      <dl className={styles.statList}>
        {aboutPage.stats &&
          aboutPage.stats.map((stat, i) => {
            const statObj = helpers.explodeText(stat);
            return (
              <div key={i} className={styles.statItem}>
                <dt className={styles.statLabel}>{statObj.label}</dt>
                <dd>
                  <h4 className={styles.statValue}>{statObj.value}</h4>
                </dd>
              </div>
            );
          })}
      </dl>
      <div role="button" className={styles.teamButton} onClick={scrollTo} onKeyDown={scrollTo} tabIndex={0}>
        <Button type="anchor" text="Meet Our Team" theme="jean" />
      </div>
    </div>
  </section>
);

function scrollTo() {
  let el;
  try {
    el = document.getElementById('peopleList');
  } catch (e) {
    // document isn't found when Gatsby builds
  }

  const boundingRect = el.getBoundingClientRect();
  const BUFFER = 30;
  boundingRect && 'scrollBehavior' in document.documentElement.style
    ? window.scrollTo({ top: boundingRect.top + window.scrollY - BUFFER, behavior: 'smooth' })
    : window.scrollTo(0, boundingRect.top + window.scrollY - BUFFER);
}

export default Introduction;

Introduction.propTypes = {
  aboutPage: PropTypes.object.isRequired,
};

export const query = graphql`
  fragment IntroductionQuery on ContentfulAboutPage {
    headline
    headlineUnderline
    summary {
      childMarkdownRemark {
        html
      }
    }
    stats
  }
`;
