import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import TextUnderline from '../../TextUnderline';
import * as styles from './timeline.module.scss';

const Timeline = ({ aboutPage }) => (
  <section className={styles.timeline}>
    <div className={styles.inner}>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>
          <TextUnderline
            headline={aboutPage.timelineTitle}
            headlineUnderline={aboutPage.timelineUnderline}
          />
        </h2>
      </div>
    </div>

    <figure className={styles.imageWrapper}>
      {aboutPage.timelineImage && aboutPage.timelineImage.file && (
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${aboutPage.timelineImage.file.url})` }}
        />
      )}
    </figure>
  </section>
);

export default Timeline;

Timeline.propTypes = {
  aboutPage: PropTypes.object.isRequired,
};

export const query = graphql`
  fragment TimelineQuery on ContentfulAboutPage {
    timelineTitle
    timelineUnderline
    timelineImage {
      file {
        url
      }
    }
  }
`;
