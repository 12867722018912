// extracted by mini-css-extract-plugin
export var introduction = "introduction-module--introduction--U4wy8";
export var inner = "introduction-module--inner--2-Rki";
export var title = "introduction-module--title--2OFO4";
export var summary = "introduction-module--summary--2rUEB";
export var statList = "introduction-module--statList--1CuyL";
export var statItem = "introduction-module--statItem--1jbHr";
export var statLabel = "introduction-module--statLabel--1sH3_";
export var statItemTitle = "introduction-module--statItemTitle--1cvGy";
export var statValue = "introduction-module--statValue--1xCNa";
export var teamButton = "introduction-module--teamButton--35qGd";